@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 85 94% 65%;
    --primary-foreground: 120 100% 5%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --success: 119 61% 33%;
    --success-foreground: 120 100% 5%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --disabled: 210 17% 95%;
    --disabled-foreground: 210 11% 71%;

    /* These are for dark buttons */
    --dark: 220 8% 23%;
    --dark-foreground: 0 0% 100%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: var(--primary);

    --radius: 0.5rem;

    /* Breakpoints imported from the original Mantine theme */
    --breakpoint-xxs: 24em;
    --breakpoint-xs: 36em;
    --breakpoint-sm: 48em;
    --breakpoint-md: 62em;
    --breakpoint-lg: 75em;
    --breakpoint-xl: 88em;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {

  body,
  html {
    @apply h-full;
  }

  body {
    @apply bg-background text-foreground;
  }

  *,
  :after,
  :before {
    @apply border-border;
  }

  body:has(.lock-body-scroll) {
    overflow: hidden;
  }

  button,
  input,
  select,
  textarea {
    font: inherit;
  }

  button,
  select {
    text-transform: none;
  }
}

@layer utilities {

  /* Utility class that will lock the body from scrolling when applied anywhere in the DOM */
  .lock-body-scroll {
    visibility: visible;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .field-sizing-content {
    field-sizing: content;
  }
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  background-color: var(--color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-x'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M18 6l-12 12' /%3E%3Cpath d='M6 6l12 12' /%3E%3C/svg%3E");
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  cursor: pointer;
  transition: background-color 0.35s ease;
}

.gm-style .gm-style-iw {
  padding: 0px !important;
  border-radius: 12px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  width: fit-content !important;
  overflow-wrap: anywhere !important;
  max-width: none !important;
}

.gm-style-iw-d {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
